import React, { useEffect } from "react"

// components
import Step from "../layout/step"

const Steps = ({ lang }) => {
  const { title, steps } = lang
  useEffect(() => {}, [])
  return (
    <section className="bg-darkBlue bg-opacity-80 w-full pt-24 pb-32">
      <div className="max-w-1024 px-2x mx-auto">
        <h1 className="font-heading text-white">{title}</h1>
        <div className="mb-10">
          <Step num="1">{steps[0]}</Step>
          <Step num="2">{steps[1]}</Step>
          <Step num="3">{steps[2]}</Step>
          <Step num="4">{steps[3]}</Step>
          <Step num="5">{steps[3]}</Step>
          <Step num="6">{steps[4]}</Step>
          <Step num="7">{steps[5]}</Step>
        </div>
      </div>
    </section>
  )
}

export default Steps
