import React from "react"

// components
import CoreSVG from "../../svg/core"

const Core = ({ lang }) => {
  const { title, body } = lang
  return (
    <section className="max-w-1024 mx-auto px-2x flex desktop:justify-start my-16 mobile:flex-col mobile:justify-between">
      <div className="max-w-550 mobile:mx-auto mobile:text-center">
        <hr
          className="w-20 mobile:mx-auto border border-blue-500 b-6"
          style={{ borderTop: "4px solid #1973CE" }}
        />
        <h1 className="heading">{title}</h1>
        <p className="mt-4 body">{body}</p>
      </div>
      <div className="desktop:w-350 mobile:w-full mobile:max-w-450 mobile:mt-10 ml-14 mobile:mx-auto">
        <CoreSVG />
      </div>
    </section>
  )
}

export default Core
