import React from "react"

const Step = ({ num, children }) => {
  return (
    <>
      <div className="flex flex-wrap  justify-start my-4">
        <div className="flex items-center justify-center bg-lightGreen font-bold text-2xl w-8 min-h-8">
          <p className="heading">{num}</p>
        </div>
        <p className="m-0 px-4 mobile:py-2 flex items-center bg-gray-100 body h-8 mobile:h-auto mobile:w-full">
          {children}
        </p>
      </div>
    </>
  )
}

export default Step
