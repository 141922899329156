import React from "react"
import Img from "gatsby-image"
import HeaderBtn from "../layout/headerButtons"

const Landing = ({ fluid, lang, langType }) => {
  const { title, body } = lang
  return (
    <section className="w-full h-550 relative desktop:mt-32">
      <div
        className="absolute max-w-1024 mx-auto left-0 right-0 desktop:px-2x h-550"
        style={{ zIndex: "-10" }}
      >
        <Img className="h-full" fluid={fluid} loading="eager" />
      </div>
      <div className="mobile:mt-24 desktop:p-10 desktop:pl-16 mobile:px-4 mobile:pt-6 container max-w-1024 h-550">
        <div className="max-w-550 ">
          <div>
            <h1 className="font-heading text-white">{title}</h1>

            <p className="font-body text-white">{body[0]}</p>
            <p className="font-body text-white">{body[1]}</p>
            <p className="font-body text-white">{body[2]}</p>
          </div>
          <HeaderBtn
            linkTo={
              langType === "en"
                ? "/energy-retrofits/#more-info"
                : "/es/energy-retrofits/#more-info"
            }
          />
        </div>
      </div>
    </section>
  )
}

export default Landing
